(function () {
	const nav = document.querySelector('.js-nav');

	const triggerSubmenu = document.querySelectorAll('.js-trigger-submenu');

	const shadow = document.querySelector('.navigation-shadow ');

	const openMenus = document.querySelectorAll('.js-trigger-submenu');

	const triggerNav = document.querySelectorAll('.js-trigger-nav');

	const menuButton = document.querySelectorAll('.js-menu-btn');

	const triggerNavEx = document.querySelector('.js-trigger-nav');

	const content = document.querySelector('#content')

	triggerSubmenu.forEach((link) => {
		link.onclick = (event) => {
			if (openMenus) {
				openMenus.forEach((openMenu) => {
					if (openMenu !== event.currentTarget) {
						openMenu.classList.remove('open');
						openMenu
							.closest('.layouts.level-1.slide')
							?.classList.remove('slide');
						openMenu.nextElementSibling?.classList.remove('show');
					}
				});
			}

			const parent = event.currentTarget.closest('.layouts.level-1');
			const submenu = event.currentTarget.nextElementSibling;

			if (parent) {
				parent.classList.toggle('slide');
			}

			if (submenu) {
				submenu.classList.toggle('show');
				if (submenu.classList.contains('show')) {
					shadow.classList.add('show');
				} else {
					shadow.classList.remove('show');

				}
			}

			Array.from(
				document.querySelectorAll(
					'.level-1.slide > .nav-item:not(.slide-nav-hide)'
				)
			).forEach((a) => {
				let liAOnlyChild = a.querySelector('.nav-item > a:only-child');
				let liNavClosed = a.querySelector(
					'.nav-item > nav:not(nav.show)'
				);

				if (liAOnlyChild) {
					liAOnlyChild.parentElement.classList.toggle(
						'slide-nav-hide'
					);
				}
				if (liNavClosed) {
					liNavClosed.parentElement.classList.toggle(
						'slide-nav-hide'
					);
				}
			});
		};
	});

	if (triggerNav) {


		triggerNav.forEach(
			(element) =>
				(element.onclick = () => {

					Array.from(
						document.querySelectorAll(
							'.level-1 > .nav-item.slide-nav-hide'
						)
					).forEach((a) => {
						a.classList.remove('slide-nav-hide');
					});

					const body = document.querySelector('body');
					body.classList.toggle('overflow-hidden');
					const subSlide = document.querySelector('.js-nav .slide');
					const subShow = document.querySelector('.js-nav .show');

					if (subSlide) {
						subSlide.classList.toggle('slide');
					}

					if (subShow) {
						subShow.classList.toggle('show');
					}

					menuButton.forEach((topMenu) => {
						topMenu.classList.toggle('d-none');
					});

					if (nav) {
						const ariaExpanded = triggerNavEx.getAttribute('aria-expanded');

						if (nav.classList.contains('open')) {
							shadow.classList.remove('show');
							triggerNavEx.setAttribute('aria-expanded', false);
						}else
						{
							triggerNavEx.setAttribute('aria-expanded', true);
						}
						nav.classList.toggle('open');
					}
				})
		);
	}

	const closeMenuItems = document.querySelectorAll('.js-close-large-menu');

	if (closeMenuItems) {
		closeMenuItems.forEach((closeButton) => {
			closeButton.addEventListener('click', () => {
				triggerSubmenu.forEach((element) => {
					let nav = element.parentElement.querySelector('nav.show');
					if (nav) {
						nav.classList.remove('show');
						shadow.classList.remove('show');
					}
				});
			});
		});
	}

	const triggerSliderBack = document.querySelectorAll('.js-slide-back');

	if (triggerSliderBack) {
		triggerSliderBack.forEach((slideBack) => {
			slideBack.onclick = () => {
				Array.from(
					document.querySelectorAll(
						'.level-1 > .nav-item.slide-nav-hide'
					)
				).forEach((a) => {
					a.classList.remove('slide-nav-hide');
				});

				let list = document.querySelector('.slide');
				let subList = document.querySelector('.show');

				if (list) {
					list.classList.toggle('slide');
				}

				if (subList) {
					subList.classList.toggle('show');
				}
			};
		});
	}

	shadow.onclick = (event) => {
		const subSlide = document.querySelector('.js-nav .slide');
		const subShow = document.querySelector('.js-nav .show');

		event.target.classList.remove('show');
		if (subSlide) {
			subSlide.classList.remove('slide');
		}

		if (subShow) {
			subShow.classList.remove('show');
		}

		if (nav) {
			nav.classList.remove('open');
		}
	};

	let lastScroll = 0;

	window.addEventListener('scroll', () => {
		const scrollDown = 'scroll-down';
		const scrollUp = 'scroll-up';
		
		const header = document.querySelector('#header');
		const messageCenter = document.querySelector(
			"[aria-labelledby='message-center-header']"
		);
		
		const currentScroll = window.pageYOffset;
		if (currentScroll <= 0) {
			header.classList.remove(scrollUp);
			header.classList.remove(scrollDown);
			content.classList.remove('fix-top');
			return;
		}

		if (
			currentScroll > lastScroll &&
			!header.classList.contains(scrollDown)
		) {
			header.classList.remove(scrollUp);
			header.classList.add(scrollDown);
			content.classList.add('fix-top');
			if (messageCenter) {
				messageCenter.classList.remove('show');
				messageCenter.classList.add('d-none');
			}
		} else if (
			currentScroll < lastScroll &&
			header.classList.contains(scrollDown)
		) {
			header.classList.remove(scrollDown);
			header.classList.add(scrollUp);
			content.classList.remove('fix-top');
		}
		lastScroll = currentScroll;
	});
})();
